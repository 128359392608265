import { Component, OnInit, Input } from '@angular/core';
import { CobranzaInterface } from '../models/cobranzas-interface';
import { DataApiService } from '../services/data-api.service';

@Component({
  selector: 'app-cobranza',
  templateUrl: './cobranza.component.html',
  styleUrls: ['./cobranza.component.css']
})
export class CobranzaComponent implements OnInit {

  @Input() cobranza: CobranzaInterface;
  constructor(
    private dataService: DataApiService,
  ) { }

  ngOnInit(): void {
  }

  getCompName(cobranza){
    return this.dataService.getCompCode(cobranza.comp_id);
  }

  getMpName(mp_id){
    return this.dataService.getMpName(mp_id);
  }

  getMpBankName(bank_id){
    return this.dataService.getMpBankName(bank_id);
  }

  getDate(string){
    return new Date(string);
  }

}
