<app-navbar></app-navbar>
<!-- descargar -->
<div class="container" id="container">
    <div class="row">
        <div class="col-md-12 float-right mb-3 mt-3">
            <button type="button" class="btn btn-info float-right" kendo-button (click)="pdf.saveAs('cobranza.pdf')"><i class="fa fa-download"></i> Descargar PDF</button>
        </div>
        <div class="col-12">
            <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
                <app-totales [cobranzas]="cobranza.cobranzas"></app-totales>
                <div class="saved-date">
                    Fecha de descarga: {{ getNewDate() | date: 'dd-MM-yyyy HH:mm' }}
                </div>
                <app-cobranza [cobranza]="c" *ngFor="let c of cobranza.cobranzas"></app-cobranza>
            </kendo-pdf-export>
        </div>
    </div>
</div>