import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ListadoCobranzaComponent } from './components/listado-cobranza/listado-cobranza.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PaginaErrorComponent } from './components/pagina-error/pagina-error.component';
import { PopupComponent } from './components/popup/popup.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//services
import { DataApiService } from './services/data-api.service';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';

//spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { MostrarCobranzasComponent } from './components/mostrar-cobranzas/mostrar-cobranzas.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DetalleCobranzaComponent } from './detalle-cobranza/detalle-cobranza.component';
import { CobranzaComponent } from './cobranza/cobranza.component';
import { TotalesComponent } from './totales/totales.component';
import { ClientesCobradosComponent } from './components/clientes-cobrados/clientes-cobrados.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ListadoCobranzaComponent,
    NavbarComponent,
    PaginaErrorComponent,
    PopupComponent,
    MostrarCobranzasComponent,
    DetalleCobranzaComponent,
    CobranzaComponent,
    TotalesComponent,
    ClientesCobradosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    PDFExportModule,
    BrowserAnimationsModule
  ],
  providers: [
    DataApiService,
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
