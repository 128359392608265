import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { UserInterface } from '../models/user-interface';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  headers : HttpHeaders = new HttpHeaders({
    "Content-Type" : "application/json"
  })

  loginUser(username: string, pass: string){
    var params = new HttpParams()
    .set('username', username)
    .set('password', pass)
    return this.http.post('api/login', params);
  }

  setUser(user){
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", user_string)
  }

  getCurrentUser(){
    let user_string = localStorage.getItem("currentUser");
    if ( ! isNullOrUndefined(user_string) ){
      return JSON.parse(user_string);
    } else {
      return null;
    }
  }

  isLogged(){
    var user = this.getCurrentUser();
    if ( user ){
      return true;
    }
    return false;
  }

  logoutUser(){
    localStorage.removeItem("currentUser");
    this.http.get('api/logout');
  }

  setToken(){
  }

  getToken(){
    return "token";
  }

}
