import { Component, OnInit, Input } from '@angular/core';
import { DataApiService } from '../services/data-api.service';

@Component({
  selector: 'app-totales',
  templateUrl: './totales.component.html',
  styleUrls: ['./totales.component.css']
})
export class TotalesComponent implements OnInit {

  @Input() cobranzas: Array<any> = [];
  constructor(
    private dataService: DataApiService
  ) { }

  ngOnInit(): void {
  }

  getTotalCobranzas(){
    return this.dataService.getTotalCobranzas(this.cobranzas);
  }
  
  getTotalCheques(){
    return this.dataService.getTotalCheques(this.cobranzas);
  }

  getTotalTalones(){
    return this.dataService.getTotalTalones(this.cobranzas);
  }

  getTotalRecibos(){
    return this.dataService.getTotalRecibos(this.cobranzas);
  }

  getTotalOtros(){
    return this.dataService.getTotalOtros(this.cobranzas);
  }

  getTotalEfectivo(){
    return this.dataService.getTotalEfectivo(this.cobranzas);
  }


}
