<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-5">
                <h5 class="card-title">
                    {{ cobranza.cliente.ape }}, {{ cobranza.cliente.name }}
                </h5>
                <h6 class="card-subtitle mb-2 text-muted">Cliente {{ cobranza.cliente.customer_id }}</h6>
            </div>
            <div class="col-3 code">
                <div *ngIf="cobranza.cod">{{ cobranza.cod }}</div>
                <div *ngIf="cobranza.id">RX-{{ cobranza.id }}</div>
                <div *ngIf="!cobranza.cod && cobranza.comprob_id">{{ getCompName(cobranza) }}-{{ cobranza.comprob_id }}-{{ cobranza.cuota }}</div>
            </div>
            <div class="col-4 col-auto mps">
                <div *ngIf="cobranza.mps[0]; then thenBlock else elseBlock"></div>
                
                <ng-template #thenBlock>

                    <div class="text-right my-1 small text-muted" *ngFor="let mp of cobranza.mps;">
                        <span>{{ getMpName(mp.mp_id) }} - {{ mp.monto | currency}}</span>
                    
                        <div *ngIf="mp.banco_id;">
                            Banco: {{ getMpBankName(mp.banco_id) }}
                            <div>
                                <span *ngIf="mp.fecha_vto">Vto: {{ getDate(mp.fecha_vto) | date:'dd-MM-yyyy' }}</span>
                                <span *ngIf="mp.nro_interno"> - N° {{ mp.nro_interno }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #elseBlock>
                    <div class="text-right my-1 small text-muted">
                        <span>Efectivo {{ cobranza.mt | currency }}</span>
                    </div>
                </ng-template>

                <div class="" id="texto">Total {{ cobranza.mt | currency }} </div>
            </div>
        </div>
    </div>