import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators';

import { CobranzaInterface } from '../models/cobranzas-interface';
import { AuthService } from './auth.service';
import { ClienteInterface } from '../models/cliente-interface';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  
  constructor(private http: HttpClient, private authService: AuthService) { }
  
  mps : Array<any> = [];
  bancos : Array<any> = [];
  cobranzas : Observable<any> [];
  cobranzasAnteriores: any[] = [];
  stateFormCobranzasAnteriores = {
    searched : false,
    searching : false,
    searchParams : {
      fecha_ini : '',
      fecha_fin : '',
    }
  };

  public selectCobranza: CobranzaInterface = {
    id: null,
    cia_id: null,
    comp_id: null,
    sucur_id: null,
    comprob_id: null,
    customer_id: null,
    cod: '',
    fecha: '',
    mt: null,
    cliente: null,
    cuota:null,
    mps: [],
  };

  comps = {
    1  : 'FA',
    2  : 'FB',
    5  : 'NC A',
    6  : 'NC B',
    7  : 'ND A',
    8  : 'ND B',
    18 : 'PP',
    17 : 'PPD',
  };
  EFECTIVO = 1;
  CHEQUE = 2;
  mpFecha = [2,3,8,10,11,13,14,15,17,21,22,23,27];

  setCobranzaActual(cobranzas){
    this.cobranzas = cobranzas;
  }

  getCobranzaActual(){    
    return this.http.get('api/cobranzaActual');
  }

  getClientesCobrados(fecha_ini: string, fecha_fin: string, customer_id: string, rs: string) {
    if ( fecha_ini && fecha_fin ){
      var params = new HttpParams()
      .set('fecha_ini', fecha_ini)
      .set('fecha_fin', fecha_fin)
      if ( rs ){
        params = params.set('rs', rs);
      }
      if ( customer_id ){
        params = params.set('customer_id', customer_id);
      }
      return this.http.get('api/clientesCobrados', { params : params });
    } else {
      throw new Error("Params not received");
    }
  }

  getCobranzasAnteriores(fecha_ini, fecha_fin): Observable<any>{
    if ( fecha_ini && fecha_fin ){
      var params = new HttpParams()
      .set('fecha_ini', fecha_ini)
      .set('fecha_fin', fecha_fin)
      return this.http.get('api/cobranzasAnteriores', { params : params });
    } else {
      return this.http.get('api/cobranzasAnteriores', {});
    }
  }
  
  setCobranzasAnteriores(cobranzasAnteriores: any[]) {
    this.cobranzasAnteriores = cobranzasAnteriores;
  }

  setSearchedCobranzasAnteriores(value:boolean){
    this.stateFormCobranzasAnteriores.searched = value;
  }
  setSearchParamsCobranzasAnteriores(params){
    this.stateFormCobranzasAnteriores.searchParams = params;
  }

  getStateFormCobranzasAnteriores(){
    return this.stateFormCobranzasAnteriores;
  }

  getCobranzaAnteriorByFecha(fecha){
    for (const cobranza of this.cobranzasAnteriores) {
      if ( cobranza.fecha == fecha ) {
        return cobranza;
      }
    }
  }

  getCobranzasSinCobrarPorcliente(numCli: String, codigo: String){
    return this.http.get(`api/customers/${numCli}/${codigo}`);
  }

  getMpErrors(mp): Array<string>{
    var errors = [];
    if ( this.needsFecha(mp.mp_id) && !mp.fecha_vto ){
      errors.push('Complete la fecha');
      return errors;
    }
    if ( isNaN(mp.monto) || !mp.monto ){
      errors.push('El monto es incorrecto');
      return errors;
    }
    return errors;
  }

  getReciboErrors(recibo): Array<string>{
    var errors = [];
    if ( !recibo.id || isNaN(recibo.id) || recibo.id < 0 ){
      errors.push('El N° de recibo es incorrecto');
      return errors;
    }
    if ( !recibo.mt || isNaN(recibo.mt) || recibo.mt < 0 ){
      errors.push('El monto es incorrecto');
      return errors;
    }
    if ( !recibo.customer_id || isNaN(recibo.customer_id) || recibo.customer_id < 0 ){
      errors.push('El N° de cliente es incorrecto');
      return errors;
    }
    return errors;
  }

  needsFecha(mp_id){
    for (const id of this.mpFecha) {
      if ( id == mp_id ){
        return true;
      }
    }
    return false;
  }

  saveCobranzas(cobranzas, mps){
    var params = new HttpParams()
    .set('cobranzas', JSON.stringify(cobranzas))
    .set('mps', JSON.stringify(mps))
    return this.http.post(`api/cobranza/add`, params);
  }

  addCobranza(cobranza){
    this.cobranzas.push(cobranza);
  }

  deleteCobranza(cobranza): Observable<any>{
    var params = new HttpParams()
    .set('cobranza', JSON.stringify(cobranza))
    return this.http.post('api/cobranza/delete', params);
  }

  searchPP(barcode:string){
    var params = new HttpParams()
    .set('barcode', barcode)
    return this.http.get('api/pp/', { params : params });
  }

  verificarRecibo(recibo){
    var params = new HttpParams()
    .set('customer_id', recibo.customer_id)
    .set('id', recibo.id)
    return this.http.get('api/recibo/', { params : params });
  }

  reciboYaEstaAgregado(id, cobranzas){
    for (const cobranza of cobranzas) {
      if ( cobranza.id && cobranza.id == id ){
        return true;
      }
    }
    return false;
  }

  codYaEstaAgregado(cod, customer_id, cobranzas){
    for (const cobranza of cobranzas) {
      if ( cobranza.cod && cobranza.cod == cod && cobranza.customer_id == customer_id ){
        return true;
      }
    }
    return false;
  }

  barcodeYaEstaAgregado(barcode, cobranzas){
    var comp = this.splitBarcode(barcode);
    for (const cobranza of cobranzas) {
      if ( this.esComprob(cobranza) && this.sameComp(cobranza, comp) ){
        return true;
      }
    }
    return false;
  }

  esRecibo(cobranza){
    return cobranza.id;
  }

  esComprob(cobranza){
    return cobranza.comprob_id;
  }

  splitBarcode(barcode){
    var cia_id     = Number(barcode.substr(0, 2));
    var comp_id    = Number(barcode.substr(2, 2));
    var sucur_id   = Number(barcode.substr(4, 4));
    var comprob_id = Number(barcode.substr(8, 8));
    var cuota      = Number(barcode.substr(16, 2));
    return { cia_id, comp_id, sucur_id, comprob_id, cuota };
  }

  sameComp(comprob1, comprob2){
    return comprob1.cia_id == comprob2.cia_id
      && comprob1.comp_id == comprob2.comp_id
      && comprob1.sucur_id == comprob2.sucur_id
      && comprob1.comprob_id == comprob2.comprob_id
      && comprob1.cuota == comprob2.cuota;
  }

  getMps(){    
    return this.http.get('api/mps');
  }
  
  setMps(mps){
    this.mps = mps;
  }

  getBancos(){    
    return this.http.get('api/bancos');
  }
  setBancos(bancos){
    this.bancos = bancos;
  }

  getCompCode(comp_id){
    return this.comps[comp_id];
  }

  getMpName(mp_id){
    for (const mp of this.mps) {
      if ( mp_id == mp.mp_id ){
        return mp.descr;
      }
    }
  }

  getMpBankName(banco_id){
    for (const banco of this.bancos) {      
      if ( banco_id == banco.banco_id ){
        return banco.descr;
      }
    }
  }

  cargarCobranza(): Observable<any>{
    return this.http.post('api/cobranza/save', {});
  }

  getTotalCobranzas(cobranzas){
    var total = 0;
    for (const cobranza of cobranzas) {
      total += Math.round(cobranza.mt * 100);
    }
    return total / 100;
  }

  getCheques(cobranzas){
    var cheques = [];
    for (const cobranza of cobranzas) {
      var chequesCobranza = cobranza.mps.filter((mp) => {
        return mp.mp_id == this.CHEQUE;
      });
      for (const cheque of chequesCobranza) {
        // filtro los que ya estan agregado ( los mps de pago se pueden repetir )
        var results = cheques.filter((c) => {
          return c.nro_interno == cheque.nro_interno && c.banco_id == cheque.banco_id;
        });
        if ( !results.length ){
          cheques.push(cheque);
        }
      }
    }
    return cheques;
  }

  getTotalCheques(cobranzas){
    var cheques = this.getCheques(cobranzas);
    var total = 0;
    for (const cheque of cheques) {
      total += Math.round(cheque.monto * 100);
    }
    return total / 100;
  }

  getOtros(cobranzas){
    var otros = [];
    for (const cobranza of cobranzas) {
      var otrosCobranza = cobranza.mps.filter((mp) => {
        return mp.mp_id != this.CHEQUE && mp.mp_id != this.EFECTIVO;
      });
      for (const mp of otrosCobranza) {
        // filtro los que ya estan agregado ( los mps de pago se pueden repetir )
        var results = otros.filter((o) => {
          return o.nro_interno == mp.nro_interno && o.banco_id == mp.banco_id && o.mp_id == mp.mp_id;
        });
        if ( !results.length ){
          otros.push(mp);
        }
      }
    }
    return otros;
  }

  getTotalTalones(cobranzas){
    var total = 0;
    for (const cobranza of cobranzas) {
      if ( cobranza.comprob_id ){
        total += Math.round(cobranza.mt * 100);
      }
    }
    return total / 100;
  }

  getTotalRecibos(cobranzas){
    var total = 0;
    for (const cobranza of cobranzas) {
      if ( cobranza.id && !cobranza.comprob_id ){
        total += Math.round(cobranza.mt * 100);
      }
    }
    return total / 100;
  }

  getTotalOtros(cobranzas){
    var otros = this.getOtros(cobranzas);
    var total = 0;
    for (const otro of otros) {
      total += Math.round(otro.monto * 100);
    }
    return total / 100;
  }

  getTotalEfectivo(cobranzas){
    var totalCheques = Math.round(this.getTotalCheques(cobranzas) * 100);
    var totalOtros = Math.round(this.getTotalOtros(cobranzas) * 100);
    var total = Math.round(this.getTotalCobranzas(cobranzas) * 100);
    return (total - totalCheques - totalOtros) / 100;
  }

  getUltimoDiaDelMes(): string {
    var date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    return date.toISOString().split('T')[0];
  }
  getPrimerDiaDelMes(): string {
    var date = new Date();
    date.setDate(1);
    return date.toISOString().split('T')[0];
  }

  pad(num, size){     
    return ('00000000000' + num).substr(-size); 
  }
  getFormattedComprob(comprob){
    if ( comprob.cuota ){
      return this.getCompCode(comprob.comp_id)+'-'+this.pad(comprob.sucur_id, 4)+'-'+this.pad(comprob.comprob_id, 8)+'-'+ this.pad(comprob.cuota, 2);
    } 
    return this.getCompCode(comprob.comp_id)+'-'+this.pad(comprob.sucur_id, 4)+'-'+ this.pad(comprob.comprob_id, 8);
  }

  getFormattedRecibo(recibo){
    return 'RX-'+ this.pad(recibo.id, 8);
  }

}
