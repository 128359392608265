import { Component, OnInit } from '@angular/core';
import { DataApiService } from 'src/app/services/data-api.service';

@Component({
  selector: 'app-clientes-cobrados',
  templateUrl: './clientes-cobrados.component.html',
  styleUrls: ['./clientes-cobrados.component.css']
})
export class ClientesCobradosComponent implements OnInit {
  clientesCobrados = [];
  error: string = '';
  searching: boolean = false;
  searched: boolean = false;
  searchParams = {
    fecha_ini: '',
    fecha_fin: '',
    customer_id: '',
    rs: '',
  };
  constructor(
    private dataService: DataApiService, 
  ) { 
    this.searchParams.fecha_ini = dataService.getPrimerDiaDelMes();
    this.searchParams.fecha_fin = dataService.getUltimoDiaDelMes();
  }

  ngOnInit(): void {
  }

  getClientesCobrados(){
    this.searching = true;
    this.error = '';
    this.dataService.getClientesCobrados(this.searchParams.fecha_ini, this.searchParams.fecha_fin, this.searchParams.customer_id, this.searchParams.rs).subscribe((clientesCobrados: Array<any>) => {
      this.searching = false;
      this.searched = true;
      this.clientesCobrados = clientesCobrados;
    }, (error: any) => {
      this.error = error.error.error;
      this.searching = false;
    });
  }

  getDate(string){
    return new Date(string);
  }

  getFormattedComprob(comprob): string{
    return this.dataService.getFormattedComprob(comprob);
  }

  getFormattedRecibo(recibo: Object): string{
    return this.dataService.getFormattedRecibo(recibo);
  }

}
