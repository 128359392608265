import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { UserInterface } from 'src/app/models/user-interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  user: UserInterface = {
    user_id: null,
    username: "",
    pass: ""
  };
  errorMessage: string = '';

  ngOnInit(): void {
  }

  onLogin():void{
    this.errorMessage = '';
    this.authService.loginUser(this.user.username, this.user.pass).subscribe((user: any) => {
      if (user && user.cobrador_id){
        this.router.navigate(['/listadoCobranza'])
        this.authService.setUser(user);
      }
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });

  }

}