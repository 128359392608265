import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ListadoCobranzaComponent } from './components/listado-cobranza/listado-cobranza.component';
import { MostrarCobranzasComponent } from './components/mostrar-cobranzas/mostrar-cobranzas.component';
import { ClientesCobradosComponent } from './components/clientes-cobrados/clientes-cobrados.component';
import { PaginaErrorComponent } from './components/pagina-error/pagina-error.component';
import { AuthGuard } from './auth/auth.guard';
import { DetalleCobranzaComponent } from './detalle-cobranza/detalle-cobranza.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'listadoCobranza', component: ListadoCobranzaComponent, canActivate: [AuthGuard] },
  { path: 'mostrarCobranzas', component: MostrarCobranzasComponent, canActivate: [AuthGuard] },
  { path: 'clientes', component: ClientesCobradosComponent, canActivate: [AuthGuard] },
  { path: 'detalleCobranza/:fecha', component: DetalleCobranzaComponent, canActivate: [AuthGuard] },
  { path: '**', component: PaginaErrorComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
