<app-navbar></app-navbar>
<div class="container">
    <h4 class="my-3" *ngIf="!searched">Clientes</h4>
    <h4 class="my-4" *ngIf="clientesCobrados.length">Mostrando {{ clientesCobrados.length }} 
        <span *ngIf="clientesCobrados.length > 1">clientes</span>
        <span *ngIf="clientesCobrados.length == 1">cliente</span>
    </h4>
    <h4 class="my-4" *ngIf="searched && !searching && !clientesCobrados.length">No se encontraron clientes...</h4>
    <div class="alert alert-danger" *ngIf="error">{{ error }}</div>
    <div class="my-4 form-inline">
        <input type="date" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.fecha_ini" placeholder="Inicio">
        <input type="date" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.fecha_fin" placeholder="Hasta">
        <input type="text" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.customer_id" placeholder="Nº cliente (opcional)">
        <input type="text" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.rs" placeholder="Nombre (opcional)">
        <div class="mr-sm-1">
            <button class="btn btn-primary mt-2 mt-md-0" (click)="getClientesCobrados()">Buscar <i class="fa fa-search"></i> </button>
        </div>
    </div>
    <div class="alert alert-info mt-2" *ngIf="searching">Buscando
        <div class="spinner-border ml-2" role="status">
        <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="card mb-3" *ngFor="let cliente of clientesCobrados">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h4>
                        {{ cliente.name }} {{ cliente.ape }} (ID {{ cliente.customer_id }})
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <h5>Facturado</h5>
                    <div class="row" *ngFor="let comprob of cliente.comprobs">
                        <div class="col-8" [ngClass]="{'text-success' : comprob.cobrada}">
                            <span *ngIf="comprob.cobrada"> <i class="fa fa-check text-success"></i></span>
                            {{ getFormattedComprob(comprob) }}
                        </div>
                        <div class="col-4" [ngClass]="{'text-success' : comprob.cobrada}">{{ comprob.mt | currency }}</div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0">
                    <h5>Cobrado</h5>
                    <div class="row" *ngFor="let comprob of cliente.ppsCobrados">
                        <div class="col-8">
                            {{ getFormattedComprob(comprob) }}
                            <span *ngIf="!comprob.same_cobrador"><i class="fa fa-warning text-warning"></i></span>
                            <span *ngIf="!comprob.same_cobrador"> ({{ comprob.cobrador_name }})</span>
                        </div>
                        <div class="col-4">{{ comprob.mt | currency }}</div>
                    </div>
                    <div class="row" *ngFor="let comprob of cliente.comprobsCobrados">
                        <div class="col-8">
                            {{ getFormattedComprob(comprob) }}
                            <span *ngIf="!comprob.same_cobrador"><i class="fa fa-warning text-warning"></i></span>
                            <span *ngIf="!comprob.same_cobrador"> ({{ comprob.cobrador_name }})</span>
                        </div>
                        <div class="col-4">{{ comprob.mt | currency }}</div>
                    </div>
                    <div class="row" *ngFor="let recibo of cliente.recibosCobrados">
                        <div class="col-8">
                            {{ getFormattedRecibo(recibo) }}
                            <span *ngIf="!recibo.same_cobrador"><i class="fa fa-warning text-warning"></i></span>
                            <span *ngIf="!recibo.same_cobrador"> ({{ recibo.cobrador_name }})</span>
                        </div>
                        <div class="col-4">{{ recibo.mt | currency }}</div>
                    </div>
                    <div class="row" *ngIf="cliente.recibosCobrados.length == 0 && cliente.comprobsCobrados.length == 0 && cliente.ppsCobrados.length == 0">
                        <div class="col font-italic">(Ninguno)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
