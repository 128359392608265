import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mostrar-cobranzas',
  templateUrl: './mostrar-cobranzas.component.html',
  styleUrls: ['./mostrar-cobranzas.component.css']
})
export class MostrarCobranzasComponent implements OnInit {

  cobranzasAnteriores = [];
  error: string = '';
  searching: boolean = false;
  searched: boolean = false;
  searchParams = {
    fecha_ini: '',
    fecha_fin: ''
  };

  constructor(
    private dataService: DataApiService, 
    private router: Router, 
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    let state = this.dataService.getStateFormCobranzasAnteriores();
    if ( state.searched ){
      this.searched = state.searched;
      this.searchParams = state.searchParams;
      this.cobranzasAnteriores = this.dataService.cobranzasAnteriores;
    }
  }

  resetFormBusqueda(){
    this.searchParams.fecha_ini = '';
    this.searchParams.fecha_fin = '';
  }

  getLast5Cobranzas(){
    this.resetFormBusqueda();
    this.getCobranzasAnteriores();
  }
  
  getCobranzasAnteriores(){
    this.searching = true;
    this.dataService.getCobranzasAnteriores(this.searchParams.fecha_ini, this.searchParams.fecha_fin).subscribe((cobranzasAnteriores: Array<any>) => {
      this.searching = false;
      this.searched = true;
      this.cobranzasAnteriores = cobranzasAnteriores;
      this.dataService.setCobranzasAnteriores(cobranzasAnteriores);
      this.dataService.setSearchParamsCobranzasAnteriores(this.searchParams);
      this.dataService.setSearchedCobranzasAnteriores(true);
    }, (error) => {
      this.searching = false;
    });
  }

  getTotalCobranza(cobranzaAnterior){
    return this.dataService.getTotalCobranzas(cobranzaAnterior.cobranzas);
  }

  getTotalCheques(cobranzaAnterior){
    return this.dataService.getTotalCheques(cobranzaAnterior.cobranzas);
  }

  getTotalTalones(cobranzaAnterior){
    return this.dataService.getTotalTalones(cobranzaAnterior.cobranzas);
  }

  getTotalRecibos(cobranzaAnterior){
    return this.dataService.getTotalRecibos(cobranzaAnterior.cobranzas);
  }

  getTotalOtros(cobranzaAnterior){
    return this.dataService.getTotalOtros(cobranzaAnterior.cobranzas);
  }

  getTotalEfectivo(cobranzaAnterior){
    return this.dataService.getTotalEfectivo(cobranzaAnterior.cobranzas);
  }

  getDate(string){
    return new Date(string);
  }

}
