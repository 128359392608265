<nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark">
    
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/listadoCobranza']">
                    <i class="fa fa-home"></i> Home
                    <span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/mostrarCobranzas']">
                    <i class="fa fa-th-list"></i> Cobranzas anteriores
                </a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/clientes']">
                    <i class="fa fa-th-list"></i> Clientes
                </a>
            </li>
        </ul>
      
        <!-- <form class="form-inline my-2 my-lg-0">
            <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form> -->
      
        <ul class="navbar-nav ">
            <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']" (click)="onLogout()">
                <i class="fa fa-user-circle"></i>
                Salir
            </a>
            </li>
        </ul>
    </div>
</nav>