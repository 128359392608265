import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataApiService } from '../services/data-api.service';


@Component({
  selector: 'app-detalle-cobranza',
  templateUrl: './detalle-cobranza.component.html',
  styleUrls: ['./detalle-cobranza.component.css']
})
export class DetalleCobranzaComponent implements OnInit {
  fecha: string;
  cobranza: any;

  constructor(
    private router: ActivatedRoute,
    private dataService: DataApiService,
    ) {
   }

  ngOnInit(): void {
    this.fecha = this.router.snapshot.params.fecha;
    this.cobranza = this.dataService.getCobranzaAnteriorByFecha(this.fecha);
  }

  getNewDate(){
    return new Date();
  }


}
