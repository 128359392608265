<app-navbar></app-navbar>
<div class="container">
    <h4 class="my-3" *ngIf="!searched">Cobranzas anteriores</h4>
    <h4 class="my-4" *ngIf="cobranzasAnteriores.length">Mostrando {{ cobranzasAnteriores.length }} cobranzas</h4>
    <h4 class="my-4" *ngIf="searched && !searching && !cobranzasAnteriores.length">No se encontraron cobranzas...</h4>
    <div class="my-4 form-inline">
        <input type="date" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.fecha_ini" placeholder="Inicio">
        <input type="date" class="form-control mr-sm-2" [(ngModel)]="this.searchParams.fecha_fin" placeholder="Hasta">
        <div class="mr-sm-1">
            <button class="btn btn-primary" (click)="getCobranzasAnteriores()">Buscar <i class="fa fa-search"></i> </button>
        </div>
        <button class="btn btn-primary" (click)="getLast5Cobranzas()"><i class="fa fa-list"></i> Mostrar últimas 5</button>
    </div>
    <div class="alert alert-info mt-2" *ngIf="searching">Buscando
        <div class="spinner-border ml-2" role="status">
        <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="card mb-3" *ngFor="let cobranzaAnterior of cobranzasAnteriores">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h4>
                        {{ getDate(cobranzaAnterior.fecha) | date:'dd-MM-yyyy HH:mm' }}
                    </h4>
                    <a [routerLink]="['/detalleCobranza', cobranzaAnterior.fecha]"><button class="btn btn-info">Ver detalle</button></a>
                </div>
                <h3 class="col-auto">{{ getTotalCobranza(cobranzaAnterior) | currency }}</h3>
            </div>
            <div class="row">
                <div class="col-7">
                </div>
                <div class="col-5">
                    <div class="text-right my-1 small text-muted"><span class="mr-1">Efectivo</span><i class="fa fa-money mr-1"></i>{{ getTotalEfectivo(cobranzaAnterior) | currency }}</div>
                    <div class="text-right my-1 small text-muted"><span class="mr-1">Cheques</span><i class="fa fa-bank mr-1"></i>{{ getTotalCheques(cobranzaAnterior) | currency }}</div>
                    <div class="text-right my-1 small text-muted"><span class="mr-1">Otros</span><i class="fa fa-info-circle mr-1"></i>{{ getTotalOtros(cobranzaAnterior) | currency }}</div>
                </div>
            </div>
        </div>
    </div>
</div> 
