import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import { CobranzaInterface } from '../../models/cobranzas-interface';
import { ClienteInterface } from '../../models/cliente-interface';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { MpInterface } from 'src/app/models/mp-interface';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

declare var $:any;

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  errorMessage: string;
  cobranzas = [];
  searched: boolean = false;
  adding: boolean = false;
  mpList = [];
  bancosList = [];
  mps: MpInterface[] = [];
  newMp: MpInterface;
  barcode: string = '';
  newRecibo = {
    id: null,
    mt: null,
    customer_id: null,
    cliente: {},
    fecha: null,
  };
  mpBancos = [2,8,17];

  constructor(
    public dataService : DataApiService,
    public authService : AuthService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.resetNewMp();
    this.resetNewRecibo();
    // obtengo los tipos de mps
    this.dataService.getMps().subscribe((mps: Array<any>) => {
      this.dataService.setMps(mps);
      this.mpList = mps;
    });
    // obtengo los bancos
    this.dataService.getBancos().subscribe((bancos: Array<any>) => {
      this.dataService.setBancos(bancos);
      this.bancosList = bancos;
    });

    $(document).ready(function() {
      
      $('[type=checkbox]').click(function ()
      {
        var checkedChbx = $('[type=checkbox]:checked');
        if (checkedChbx.length > 0){

            $('#sent').show();
        }else{
            $('#sent').hide();
        }
        
      });


      // $('#solicitar').click(function(){
      //     $('#results').show();
      //     $('#solicitar').hide();
      // });

      
      var activeSystemClass = $('.list-group-item.active');
  
      $('#system-search2').keyup( function() {
         var that = this;
          // affect all table rows on in systems table
          var tableBody = $('#table-list-search2 tbody');
          var tableRowsClass = $('#table-list-search2 tbody tr');
          $('.search-sf').remove();
          tableRowsClass.each( function(i, val) {
          
              //Lower text for case insensitive
              var rowText = $(val).text().toLowerCase();
              var inputText = $(that).val().toLowerCase();
              if(inputText != '')
              {
                  $('.search-query-sf').remove();
                  tableBody.prepend('<tr class="search-query-sf"><td colspan="6"><strong>Busqueda: "'
                      + $(that).val()
                      + '"</strong></td></tr>');
              }
              else
              {
                  $('.search-query-sf').remove();
              }
  
              if( rowText.indexOf( inputText ) == -1 )
              {
                  //hide rows
                  tableRowsClass.eq(i).hide();
                  
              }
              else
              {
                  $('.search-sf').remove();
                  tableRowsClass.eq(i).show();
              }
          });
          //all tr elements are hidden
          if(tableRowsClass.children(':visible').length == 0)
          {
              tableBody.append('<tr class="search-sf"><td class="text-muted" colspan="6">No entries found.</td></tr>');
          }
      });
    });

  }

  onSaveCobranzas():void{
    this.errorMessage = null;
    if ( this.cobranzaIsValid() ) {
      this.adding = true;
      this.dataService.saveCobranzas(this.cobranzas, this.mps).subscribe(() => {
        this.adding = false;
        for (const cobranza of this.cobranzas){
          cobranza.mps = this.mps;
          this.dataService.addCobranza(cobranza);
        }
        this.resetCobranzas();
        $('#modalCobranza').modal('hide');
      }, (error: any) => {
        this.adding = false;
        this.errorMessage = error.error.error;
      });
    }
  }

  onAddMp():void{
    this.errorMessage = null;
    if ( !this.isBanco(this.newMp.mp_id) ){
      this.newMp.banco_id = null;
    }
    if ( !this.dataService.needsFecha(this.newMp.mp_id) ){
      this.newMp.fecha_vto = (new Date()).toISOString();
    }
    let errors = this.dataService.getMpErrors(this.newMp);
    if ( errors.length ){
      this.errorMessage = errors[0];
      return ;
    }
    this.mps.push(this.newMp);
    this.resetNewMp();
  }

  
  resetNewMp():void{
    this.newMp = {
      mp_id: 1,
      monto: null,
      banco_id: 1,
      fecha_vto: '',
      nro_interno: '',
      descr: '',
    };
  }

  resetNewRecibo():void{
    this.newRecibo = {
      id: null,
      mt: null,
      customer_id: null,
      cliente: {},
      fecha: null,
    };
  }

  onSearchCobranza(cobranzaForm: NgForm){
    this.errorMessage = null;
    if ( this.dataService.codYaEstaAgregado(cobranzaForm.value.codigo, cobranzaForm.value.cliente, this.cobranzas) ){
      this.errorMessage = 'El comprobante '+cobranzaForm.value.codigo+' ya está agregado al listado';
      return ;
    }
    this.dataService.getCobranzasSinCobrarPorcliente(cobranzaForm.value.cliente, cobranzaForm.value.codigo).subscribe((cobranzas: Observable<any>[]) => {
      console.log('results', cobranzas);
      if ( !cobranzas.length ){
         this.errorMessage = 'No se han encontrado comprobantes';
      } else {
        this.dataService.selectCobranza.cod = null;
        this.dataService.selectCobranza.cliente = null;
        for (const cobranza of cobranzas) {
          this.cobranzas.push(cobranza);
        }
      }
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });
  }

  onSearchPP(){
    this.errorMessage = null;
    if ( this.dataService.barcodeYaEstaAgregado(this.barcode, this.cobranzas) ){
      this.errorMessage = 'El código '+this.barcode+' ya está agregado al listado';
      return ;
    }
    this.dataService.searchPP(this.barcode).subscribe((cobranzas: Observable<any>[]) => {
      console.log('results', cobranzas);
      if ( !cobranzas.length ){
         this.errorMessage = 'No se han encontrado comprobantes';
      } else {
        this.barcode = '';
        for (const cobranza of cobranzas) {
          this.cobranzas.push(cobranza);
        }
      }
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });
  }

  onAddRecibo(){
    this.errorMessage = null;
    let errors = this.dataService.getReciboErrors(this.newRecibo);
    if ( errors.length ){
      this.errorMessage = errors[0];
      return ;
    }
    if ( this.dataService.reciboYaEstaAgregado(this.newRecibo.id, this.cobranzas) ){
      this.errorMessage = 'El recibo '+this.newRecibo.id+' ya está agregado al listado';
      return ;
    }
    this.dataService.verificarRecibo(this.newRecibo).subscribe((response: any) => {
      if ( !response.cliente ){
        this.errorMessage = `No se ha encontrado el cliente ${this.newRecibo.customer_id}`;
      } else {
        this.newRecibo.fecha = (new Date()).toISOString();
        this.newRecibo.cliente = response.cliente;
        this.cobranzas.push(this.newRecibo);
        this.resetNewRecibo();
      }
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });
  }

  resetCobranzas(){
    this.resetNewMp();
    this.resetNewRecibo();
    this.cobranzas = [];
    this.mps = [];
    this.errorMessage = null;
  }

  totalMps(){
    var total = 0;
    for (const mp of this.mps) {
      total = total + Math.round(mp.monto * 100);
    }
    return total / 100;
  }

  totalCobranzas(){
    var total = 0;
    for (const cobranza of this.cobranzas) {
      total = total + Math.round(cobranza.mt * 100);
    }
    return total / 100;
  }

  deleteMp(mp){
    this.mps.splice(this.cobranzas.indexOf(mp), 1);
  }

  deleteCobranza(cobranza){
    this.cobranzas.splice(this.cobranzas.indexOf(cobranza), 1);
  }

  isBanco(mp_id){
    for (const id of this.mpBancos) {
      if ( id == mp_id ){
        return true;
      }
    }
    return false;
  }
  isFecha(mp_id){
    return this.dataService.needsFecha(mp_id);
  }

  nameMp(mp_id){
    for (const mp of this.mpList) {
      if ( mp.mp_id == mp_id ){
        return mp.code;
      }
    }
    return '---';
  }

  nameBanco(banco_id){
    for (const banco of this.bancosList) {
      if ( banco.banco_id == banco_id ){
        return banco.code;
      }
    }
    return '---';
  }
  getDate(string){
    return new Date(string);
  }

  cobranzaIsValid(){
    if ( !this.mps.length ) { return true; }
    if ( Math.round(this.totalMps() * 100) !==  Math.round(this.totalCobranzas() * 100) ){
      this.errorMessage = `El monto de comprobantes no coincide con el de medios de pago (${this.totalMps().toFixed(2)} | ${this.totalCobranzas().toFixed(2)})`;
      return false;
    }
    return true;
  }

  getCompName(cobranza){
    return this.dataService.getCompCode(cobranza.comp_id);
  }

}
