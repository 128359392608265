import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { DataApiService } from 'src/app/services/data-api.service';
import { CobranzaInterface } from 'src/app/models/cobranzas-interface';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';


declare var $:any;

@Component({
  selector: 'app-listado-cobranza',
  templateUrl: './listado-cobranza.component.html',
  styleUrls: ['./listado-cobranza.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ListadoCobranzaComponent implements OnInit {

constructor(private dataService: DataApiService, private router: Router, public datepipe: DatePipe) { }

  cobranzas: CobranzaInterface [] = [];
  errorMessage: string = '';
  successMessage: string = '';
  saving: boolean = false;
  saved: boolean = false;
  savedDate: Date = null;
  
  @ViewChild('htmlData') htmlData:ElementRef;

  ngOnInit(): void {

    // obtiene la cobranza del cobrador actual (todavia sin pasarla)
    this.getCobranzaActual();

    // obtiene los mps del sistema
    // this.getMps();

    // filtro de búsqueda
    $(document).ready(function() {

      var activeSystemClass = $('.list-group-item.active');
  
      $('#system-search').keyup( function() {
         var that = this;
          // affect all table rows on in systems table
          var tableBody = $('#table-list-search tbody');
          var tableRowsClass = $('#table-list-search tbody tr');
          $('.search-sf').remove();
          tableRowsClass.each( function(i, val) {
          
              //Lower text for case insensitive
              var rowText = $(val).text().toLowerCase();
              var inputText = $(that).val().toLowerCase();
              if(inputText != '')
              {
                  $('.search-query-sf').remove();
                  tableBody.prepend('<tr class="search-query-sf"><td colspan="6"><strong>Busqueda: "'
                      + $(that).val()
                      + '"</strong></td></tr>');
              }
              else
              {
                  $('.search-query-sf').remove();
              }
  
              if( rowText.indexOf( inputText ) == -1 )
              {
                  //hide rows
                  tableRowsClass.eq(i).hide();
                  
              }
              else
              {
                  $('.search-sf').remove();
                  tableRowsClass.eq(i).show();
              }
          });
          //all tr elements are hidden
          if(tableRowsClass.children(':visible').length == 0)
          {
              tableBody.append('<tr class="search-sf"><td class="text-muted" colspan="6">No entries found.</td></tr>');
          }
      });
    });
  }

  getCobranzaActual(){
    this.dataService.getCobranzaActual().subscribe((cobranzas: CobranzaInterface[]) => {
      console.log('cobranzaActual', cobranzas);
      this.cobranzas = cobranzas;
      this.dataService.setCobranzaActual(cobranzas);
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });
  }

  getMps(){
    this.dataService.getMps().subscribe((mps: Observable<any>[]) => {
      this.dataService.setMps(mps);
    }, (error: any) => {
      this.errorMessage = error.error.error;
    }); 
  }

  onDeleteCobranza(cobranza):void{
    this.dataService.deleteCobranza(cobranza).subscribe((response: Observable<any>) => {
      this.cobranzas.splice(this.cobranzas.indexOf(cobranza), 1);
    }, (error: any) => {
      this.errorMessage = error.error.error;
    });
  }

  onCargarCobranzas():void{
    this.saving = true;
    this.dataService.cargarCobranza().subscribe(() => {
      this.successMessage = 'Se ha cargado correctamente';
      this.saved = true;
      this.saving = false;
      this.savedDate = new Date();
    }, (error: any) => {
      this.saving = false;
      this.errorMessage = error.error.error;
    });
  }

  getTotalCobranzas(){
    return this.dataService.getTotalCobranzas(this.cobranzas);
  }
  
  getTotalCheques(){
    return this.dataService.getTotalCheques(this.cobranzas);
  }

  getTotalTalones(){
    return this.dataService.getTotalTalones(this.cobranzas);
  }

  getTotalRecibos(){
    return this.dataService.getTotalRecibos(this.cobranzas);
  }

  getTotalOtros(){
    return this.dataService.getTotalOtros(this.cobranzas);
  }

  getTotalEfectivo(){
    return this.dataService.getTotalEfectivo(this.cobranzas);
  }

  getCompName(cobranza){
    return this.dataService.getCompCode(cobranza.comp_id);
  }

  getMpName(mp_id){
    return this.dataService.getMpName(mp_id);
  }

  getMpBankName(bank_id){
    return this.dataService.getMpBankName(bank_id);
  }

  getDate(string){
    return new Date(string);
  }


}

