<div id="modalCobranza" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
  
            <div class="modal-header">
              <h4>Agregar comprobantes</h4>
            </div>
            <div class="modal-body">
            <div class="row">
              <div class="col-6 my-2 p-3 border-right">
              <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
              <form class="form-cobranza" #formCobranza="ngForm" (ngSubmit)="onSearchCobranza(formCobranza)">
                <div class="form-group form-row">
                  <div class="col-4">
                    <label for="codigo">Código</label>
                    <input type="text" class="form-control" name="codigo" id="codigo" [(ngModel)] = "this.dataService.selectCobranza.cod" placeholder="Por ej.: SUX">
                  </div>
                  <div class="col-8">
                    <label for="cliente">N° Cliente</label>
                    <input type="text" class="form-control" name="cliente" id="cliente" [(ngModel)] = "this.dataService.selectCobranza.cliente" placeholder="Por ej.: 21401">
                  </div>
                </div>
                <div class="form-group text-center" id="solicitar">
                  <button type="submit" class="btn btn-block btn-primary">Agregar comprobante</button>
                </div>
              </form>

              <form class="form-cobranza" #formPP="ngForm" (ngSubmit)="onSearchPP()">
                <div class="form-group form-row">
                  <div class="col-12">
                    <label for="codigo">Código de barras</label>
                    <input type="text" class="form-control" name="codigo" id="codigo" [(ngModel)]="this.barcode" placeholder="Por ej.: 0118000200005199019">
                  </div>
                </div>
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-block btn-primary">Agregar comprobante</button>
                </div>
              </form>

              <form class="form-cobranza" #formRecibo="ngForm" (ngSubmit)="onAddRecibo()">
                <div class="form-group form-row">
                  <div class="col-4">
                    <label for="cliente-recibo">N° Cliente</label>
                    <input type="text" class="form-control" name="cliente" id="cliente-recibo" [(ngModel)]="newRecibo.customer_id" placeholder="Por ej.: 21401">
                  </div>
                  <div class="col-4">
                    <label for="id">N° Recibo</label>
                    <input type="text" class="form-control" name="id" [(ngModel)]="newRecibo.id" id="id">
                  </div>
                  <div class="col-4">
                    <label for="monto">Monto</label>
                    <input type="text" class="form-control" name="monto" [(ngModel)]="newRecibo.mt" id="monto">
                  </div>
                </div>
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-block btn-primary">Agregar recibo</button>
                </div>
              </form>
            <div *ngIf="cobranzas.length == 0" class="alert alert-warning">No se han agregado comprobantes todavía</div>
            <div *ngIf="searched && cobranzas.length == 0" class="alert alert-warning">No se han encontrado comprobantes</div>
            <div class="my-2" *ngIf="cobranzas.length > 0" id="results">
            
              <!-- <form action="#" method="get">
                  <div class="input-group">
                      <input class="form-control" id="system-search2" name="q" placeholder="Buscar..." required>
                      <span class="input-group-btn">
                          <button type="submit" class="btn btn-default"><i class="fa fa-search"></i></button>
                      </span>
                  </div>
              </form> -->
              <table class="table table-list-search2" id="table-list-search2">
                <thead>
                    <tr>
                        <th scope="col">Cliente</th>
                        <th scope="col">Cod/ID</th>
                        <th scope="col">Monto</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cobranza of cobranzas; index as i">
                        <td scope="row"> {{ cobranza.customer_id }}</td>
                        <td scope="row" *ngIf="cobranza.cod"> {{ cobranza.cod }}</td>
                        <td scope="row" *ngIf="cobranza.id">RX-{{ cobranza.id }}</td>
                        <td scope="row" *ngIf="!cobranza.cod && cobranza.comprob_id">{{ getCompName(cobranza) }}-{{ cobranza.comprob_id }}-{{ cobranza.cuota }}</td>
                        <td scope="row"> {{ cobranza.mt | currency }}</td>
                        <td scope="row"><button type="button" class="btn btn-xs btn-danger" (click)="deleteCobranza(cobranza)"><i class="fa fa-times"></i></button></td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td>Total: {{ totalCobranzas() | currency }}</td>
                    </tr>
                </tbody>
            </table>   
          </div>
        </div>

        <!-- agregar mps -->
        <div class="card-body col-6">
          <form class="">
            <div class="form-group form-row">
              <div class="col-8">
                <label>Medio de pago</label>
                <select class="form-control" [(ngModel)]="this.newMp.mp_id" name="mp_id">
                  <option value="{{ mp.mp_id }}" *ngFor="let mp of mpList">{{ mp.descr }}</option>
                </select>
              </div>
              <div class="col-4">
                <label>Monto</label>
                <input type="text" class="form-control" [(ngModel)]="this.newMp.monto" name="monto">
              </div>
            </div>
            <div class="form-group form-row" *ngIf="isFecha(this.newMp.mp_id)">
              <div class="col-6">
                <label>Fecha</label>
                <input type="date" class="form-control" [(ngModel)]="this.newMp.fecha_vto" name="fecha_vto">
              </div>
              <div class="col-6">
                <label>N° Interno</label>
                <input type="text" class="form-control" [(ngModel)]="this.newMp.nro_interno" name="nto_interno">
              </div>
            </div>
            <div class="form-group form-row" *ngIf="isBanco(this.newMp.mp_id)">
              <div class="col-12">
                <label>Banco</label>
                <select class="form-control" [(ngModel)]="this.newMp.banco_id" name="banco_id">
                  <option value="{{ banco.banco_id }}" *ngFor="let banco of bancosList">{{ banco.descr }}</option>
                </select>
              </div>
              <!-- <div class="col-6">
                <label>Descripción</label>
                <input type="text" class="form-control" [(ngModel)]="this.newMp.descr" name="descr">
              </div> -->
            </div>
            <button class="btn btn-primary" (click)="onAddMp()">Agregar medio de pago</button>
          </form>
          <div *ngIf="mps.length == 0" class="alert alert-info mt-2">
            Recuerde que si no se cargan medios de pagos se asume que el medio de pago utilizado fue <span class="font-weight-bold">Efectivo</span>.
          </div>
          <table class="table mt-2" *ngIf="mps.length != 0">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Banco</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Monto</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mp of mps; index as i">
                  <td scope="row">{{ nameMp(mp.mp_id) }}</td>
                  <td scope="row">{{ nameBanco(mp.banco_id) }}</td>
                  <td scope="row"> {{ getDate(mp.fecha_vto) | date:'dd-MM-yyyy' }}</td>
                  <td scope="row">{{ mp.monto | currency}}</td>
                  <td scope="row"><button type="button" class="btn btn-xs btn-danger" (click)="deleteMp(mp)"><i class="fa fa-times"></i></button></td>
                  </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Total: {{ totalMps() | currency }}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mr-3" id="sent">
        <button id="enviar" *ngIf="!adding" (click)="onSaveCobranzas()" type="button" class="btn btn-primary float-right mr-3" ><i class="fa fa-check"></i> Añadir al listado</button>
        <button id="enviar" *ngIf="adding" type="button" class="btn btn-primary float-right mr-3" disabled><i class="fa fa-check"></i> Agregando...</button>
        <button id="enviar" (click)="resetCobranzas()" type="button" class="btn btn-warning float-right mr-3" ><i class="fa fa-clear"></i> Resetear cobranza</button>
      </div>

      </div>
      </div>
    </div>
  </div>