<app-navbar></app-navbar>

<div class="container" id="container">
	<div class="row">
        <div class="col-md-3 float-left mb-3 my-3">
            
            <form action="#" method="get">
                <div class="input-group">
                    <input class="form-control" id="system-search" name="q" placeholder="Buscar..." required>
                    <span class="input-group-btn">
                        <button type="submit" class="btn btn-default"><i class="fa fa-search"></i></button>
                    </span>
                </div>
            </form>
        </div>

        <div class="col-md-9 float-right mb-3 mt-3">
            <div class="">
                <button type="button" *ngIf="!saved && !saving" class="btn btn-success float-right" (click)="onCargarCobranzas()"><i class="fa fa-check"></i> Enviar cobranza</button> 
                <button type="button" *ngIf="saving" class="btn btn-success float-right" (click)="onCargarCobranzas()" disabled><i class="fa fa-check"></i> Enviando...</button> 
                <button type="button" *ngIf="!saved" class="btn btn-primary float-right mr-3" data-toggle="modal" data-target="#modalCobranza"><i class="fa fa-plus"></i> Agregar comprobantes</button>
                <button type="button" *ngIf="saved"  class="btn btn-info float-right mr-3" kendo-button (click)="pdf.saveAs('cobranza.pdf')"><i class="fa fa-download"></i> Descargar PDF</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="alert alert-warning" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>
            <div class="alert alert-success" *ngIf="successMessage">
                {{ successMessage }}
            </div>
        </div>
		<div class="col-md-12 pt-2">
            <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
            <div class="totales">
                <span class="font-weight-bold">Total: {{ getTotalCobranzas() | currency }}</span>
                (Talones: <span class="text-success">{{ getTotalTalones() | currency }}</span>
                Recibos: <span class="text-success">{{ getTotalRecibos() | currency }}</span>
                Efectivo: <span class="text-success">{{ getTotalEfectivo() | currency }}</span>
                Cheques: <span class="text-success">{{ getTotalCheques() | currency }}</span>
                Otros: <span class="text-success">{{ getTotalOtros() | currency }}</span>)
            </div>
            <div class="saved-date" *ngIf="savedDate">
                Fecha de descarga: {{ savedDate | date: 'dd-MM-yyyy HH:mm' }}
            </div>
            <div class="card" *ngFor="let cobranza of cobranzas;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <h5 class="card-title">
                                <!-- {{ (cobranza.mps[0].mp_id) }} -->
                                {{ cobranza.cliente.ape }}, {{ cobranza.cliente.name }}
                            </h5>
                            <h6 class="card-subtitle mb-2 text-muted">Cliente {{ cobranza.cliente.customer_id }}</h6>
                        </div>
                        <div class="col-3 code">
                            <div *ngIf="cobranza.cod">{{ cobranza.cod }}</div>
                            <div *ngIf="cobranza.id">RX-{{ cobranza.id }}</div>
                            <div *ngIf="!cobranza.cod && cobranza.comprob_id">{{ getCompName(cobranza) }}-{{ cobranza.comprob_id }}-{{ cobranza.cuota }}</div>
                        </div>
                        <div class="col-4 col-auto mps">
                            <div *ngIf="cobranza.mps[0]; then thenBlock else elseBlock"></div>
                            
                            <ng-template #thenBlock>

                                <div class="text-right my-1 small text-muted" *ngFor="let mp of cobranza.mps;">
                                    <span>{{ getMpName(mp.mp_id) }} - {{ mp.monto | currency}}</span>
                                
                                    <div *ngIf="mp.banco_id;">
                                        Banco: {{ getMpBankName(mp.banco_id) }}
                                        <div>
                                            <span *ngIf="mp.fecha_vto">Vto: {{ getDate(mp.fecha_vto) | date:'dd-MM-yyyy' }}</span>
                                            <span *ngIf="mp.nro_interno"> - N° {{ mp.nro_interno }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template #elseBlock>
                                <div class="text-right my-1 small text-muted">
                                    <span>Efectivo {{ cobranza.mt | currency }}</span>
                                </div>
                            </ng-template>

                            <div class="" id="texto">Total {{ cobranza.mt | currency }} </div>
                            <button class="btn btn-sm btn-danger float-right" (click)="onDeleteCobranza(cobranza)"><i class="fa fa-trash"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            </kendo-pdf-export>
        </div>
    </div>
</div>

<app-popup></app-popup>