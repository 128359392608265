<div class="container">
	<div class="d-flex justify-content-center h-100">
		<div class="card">
			<div class="card-header">
				<h3>Acceso</h3>
			</div>
			<div class="card-body">
				<form #formLogin="ngForm" (ngSubmit)="onLogin()" >
                    <div class="group">
                        <input type="text" [(ngModel)]="user.username" name="username" required>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label><span class="span-input"><i class="fa fa-user"></i> Usuario</span></label>
                    </div>
                    
                    <div class="group">
                        <input type="password" [(ngModel)]="user.pass" name="pass" required>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label><span class="span-input"> <i class="fa fa-lock"></i> Contraseña</span></label>
                        <div class="alert alert-danger mt-2" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                    </div>
                    <div class="form-group">
						<input type="submit" value="Acceder" class="btn float-right login_btn">
					</div>

                </form>
			</div>
			<!-- <div class="card-footer">
			</div> -->
		</div>
	</div>
</div>